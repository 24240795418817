import { DateTime } from 'luxon'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'

import { Button, Checkbox, RadioButtons } from '@shared/components'
import { useFtpPortalHubCommunication } from '@shared/hooks'

import { SampleSlideOutModal } from '../sample-slide-out-modal/SampleSlideOutModal'

enum UISampleTab {
  RADIO_BUTTONS = 0,
  CHECKBOXES = 1,
  HOVER_TEXT = 2,
  I18N = 3,
  SLIDE_OUT_MODAL = 4,
}

export enum RadioButton {
  'A' = 'A',
  'B' = 'B',
  'C' = 'C',
}

const useStyles = tss.withName('UISamples').create(() => ({
  tabs: {
    backgroundColor: '#FFF',
    color: '#374151',
    font: 'Inter',
    borderRadius: '2px',
    borderBottom: '1px solid #E5E7EB',
  },
  reloadButtonContainer: {
    marginTop: '15px',
  },
}))

export const UISamples: FC = () => {
  const { t, i18n } = useTranslation()
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const [selectedTab, setSelectedTab] = useState<UISampleTab>(
    UISampleTab.RADIO_BUTTONS
  )

  const [selectedRadioButton, setSelectedRadioButton] =
    useState<RadioButton | null>(null)

  const [checkboxAChecked, setCheckboxAChecked] = useState(false)
  const [checkboxBChecked, setCheckboxBChecked] = useState(false)
  const [checkboxCChecked, setCheckboxCChecked] = useState(false)
  const [checkedCheckboxes, setCheckedCheckboxes] = useState(0)

  const [isSlideOutModalOpen, setIsSlideOutModalOpen] = useState(false)

  const tabStyle = (tabIndex: number) => ({
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: 'Inter',
    border: selectedTab === tabIndex ? '1px solid #E5E7EB' : 'none',
    borderBottom: selectedTab === tabIndex ? 'none' : '',
    borderRadius: '6px 6px 0 0',
  })

  const onTabChange = (_, selectedTab: number) => {
    setSelectedTab(selectedTab)
  }

  const onCheckBoxChange = (checked: boolean) => {
    setCheckedCheckboxes((checkedCheckboxes) =>
      checked ? checkedCheckboxes + 1 : checkedCheckboxes - 1
    )
  }

  // This useEffect is called only once when the component is mounted
  // and it sets the AppBar at the top of the page
  useEffect(() => {
    // This allows to dynamically change the breadcrumbs based on the selected tab
    let selectedTabText = ''
    switch (selectedTab) {
      case UISampleTab.RADIO_BUTTONS:
        selectedTabText = t('service-portal.radio-buttons-samples')
        break
      case UISampleTab.CHECKBOXES:
        selectedTabText = t('service-portal.checkboxes-samples')
        break
      case UISampleTab.HOVER_TEXT:
        selectedTabText = t('service-portal.hover-text-samples')
        break
      case UISampleTab.I18N:
        selectedTabText = t('service-portal.i18n')
        break
      case UISampleTab.SLIDE_OUT_MODAL:
        selectedTabText = t('service-portal.slide-out-modal-sample')
        break
      default:
        return
    }

    setAppBarTitle(
      t('service-portal.ui-samples'), // This sets the title of the AppBar
      t('service-portal.this-page-showcases'), // This adds a tooltip to the title
      [t('common.merchants'), selectedTabText], // This sets the breadcrumbs
      '/service/sample-grid' // This sets the link to the previous page
    )
  }, [selectedTab]) // This array is the dependency array, which means that this useEffect will be called every time the selectedTab changes

  return (
    <section>
      <AppBar position="relative" elevation={0} sx={{ borderRadius: '4px' }}>
        <Tabs
          textColor="inherit"
          onChange={onTabChange}
          value={selectedTab}
          className={classes.tabs}
          TabIndicatorProps={{
            style: { textTransform: 'none' },
            sx: { backgroundColor: '#D64123', fontFamily: 'Inter' },
          }}
        >
          <Tab
            label={t('service-portal.radio-buttons')}
            sx={tabStyle(0)}
            data-testid="radio-buttons-tab"
          />

          <Tab
            label={t('service-portal.checkboxes')}
            sx={tabStyle(1)}
            data-testid="checkboxes-tab"
          />

          <Tab
            label={t('service-portal.hover-text')}
            sx={tabStyle(2)}
            data-testid="hover-text-tab"
          />

          <Tab
            label={t('service-portal.i18n')}
            sx={tabStyle(3)}
            data-testid="i18n-tab"
          />

          <Tab
            label={t('service-portal.slide-out-modal')}
            sx={tabStyle(4)}
            data-testid="slide-out-modal-tab"
          />
        </Tabs>
      </AppBar>

      <Box sx={{ display: 'grid', placeContent: 'center', padding: '2em 0' }}>
        {selectedTab === UISampleTab.RADIO_BUTTONS && (
          <Box data-testid="radio-buttons-tab-container">
            <Typography
              sx={{ marginBottom: '2em' }}
              data-testid="selected-radio-button-text"
            >
              {`${t('service-portal.selected-radio-button')}: ${
                selectedRadioButton ?? t('common.none')
              }`}
            </Typography>

            <RadioButtons
              buttons={[
                {
                  testId: 'radio-button-a',
                  title: `${t('service-portal.radio-button')} A`,
                  color: 'secondary',
                  defaultSelected: selectedRadioButton === RadioButton.A,
                  onClick: () => setSelectedRadioButton(RadioButton.A),
                },
                {
                  testId: 'radio-button-b',
                  title: `${t('service-portal.radio-button')} B`,
                  color: 'secondary',
                  defaultSelected: selectedRadioButton === RadioButton.B,
                  onClick: () => setSelectedRadioButton(RadioButton.B),
                },
                {
                  testId: 'radio-button-c',
                  title: `${t('service-portal.radio-button')} C`,
                  color: 'secondary',
                  defaultSelected: selectedRadioButton === RadioButton.C,
                  onClick: () => setSelectedRadioButton(RadioButton.C),
                },
              ]}
            />
          </Box>
        )}

        {selectedTab === UISampleTab.CHECKBOXES && (
          <Box data-testid="checkboxes-tab-container">
            <Box
              sx={{
                display: 'flex',
                marginBottom: '2em',
              }}
            >
              <Typography
                sx={{ marginRight: '2em' }}
                data-testid="enabled-checkboxes-text"
              >
                {`${t('common.enabled')}: ${checkedCheckboxes}`}
              </Typography>

              <Typography data-testid="disabled-checkboxes-text">
                {`${t('common.disabled')}: ${3 - checkedCheckboxes}`}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                marginBottom: '2em',
              }}
            >
              <Checkbox
                testId="checkbox-a"
                label={`${t('service-portal.checkbox')} A`}
                checked={checkboxAChecked}
                onChange={(event) => {
                  const checked = (event.target as any).checked
                  setCheckboxAChecked(checked)
                  onCheckBoxChange(checked)
                }}
              />

              <Checkbox
                testId="checkbox-b"
                label={`${t('service-portal.checkbox')} B`}
                checked={checkboxBChecked}
                onChange={(event) => {
                  const checked = (event.target as any).checked
                  setCheckboxBChecked(checked)
                  onCheckBoxChange(checked)
                }}
              />

              <Checkbox
                testId="checkbox-c"
                label={`${t('service-portal.checkbox')} C`}
                checked={checkboxCChecked}
                onChange={(event) => {
                  const checked = (event.target as any).checked
                  setCheckboxCChecked(checked)
                  onCheckBoxChange(checked)
                }}
              />
            </Box>
          </Box>
        )}

        {selectedTab === UISampleTab.HOVER_TEXT && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            data-testid="hover-text-tab-container"
          >
            <Tooltip
              title={t('service-portal.sample-tooltip')}
              data-testid="tooltip"
            >
              <IconButton
                onClick={() =>
                  alert(t('service-portal.clicked-sample-tooltip'))
                }
              >
                <InfoOutlinedIcon color="disabled" fontSize="medium" />
              </IconButton>
            </Tooltip>

            <Typography data-testid="tooltip-description">
              {t('service-portal.tooltips-are-used-to')}
            </Typography>
          </Box>
        )}

        {selectedTab === UISampleTab.I18N && (
          // All the texts are translated using the t function with keys that contain the actual text.
          // These keys are defined in the .json files on ./shared/i18n or ./service-portal/src/i18n.

          <Box data-testid="i18n-tab-container">
            {/* This key starts with 'common', which means it comes from the ./shared directory and it is shared between all microfrontends */}
            {/* We share translations when it is a very common text that will likely appear in more than one microfrontend, just like the word below: Time */}
            <Typography data-testid="time-text">{`${t(
              'common.time'
            )}: ${DateTime.now()}`}</Typography>

            {/* For the two following texts, we are adding its translations to the service-portal .json files,
            which means they are specific texts that should only be displayed inside this package or portal */}
            <Typography data-testid="hello-text">
              {t('service-portal.hello')}
            </Typography>

            <Typography data-testid="goodbye-text">
              {t('service-portal.goodbye')}
            </Typography>

            {/* Text untranslated on purpose, to illustrate what happens if you forget to translate a string.
            You will notice that when we set the language to “Arrows” (or French), this will be the only text on the page that remains in English, and should serve as a cue to the developer that they missed a translation. */}
            <Typography data-testid="untranslated-hello-text">
              Untranslated Hello
            </Typography>

            {/* This is a key the does not exist. In case we forget to add a key to the translation files, the user will see it on the screen*/}
            <Typography data-testid="missing-key-text">
              {t('service-portal.missing-key.goodbye')}
            </Typography>

            <Grid container columnGap={2} marginTop={3}>
              <Button
                testId="english-button"
                label={t('service-portal.english')}
                onClick={() => i18n.changeLanguage('en')}
              />

              <Button
                testId="french-button"
                label={t('service-portal.french')}
                onClick={() => i18n.changeLanguage('fr')}
              />

              {/* When the user presses this button, all the texts that are covered by translations will be wrapped by < >.
              This way, we can easily detect when we forget to translate something  */}
              <Button
                testId="arrows-button"
                label={t('service-portal.arrows')}
                onClick={() => i18n.changeLanguage('arrows')}
              />
            </Grid>

            <Button
              testId="reload-button"
              label={t('common.reload')}
              onClick={() => window.location.reload()}
              containerClassName={classes.reloadButtonContainer}
            />

            <Typography data-testid="reload-text">
              {t('service-portal.reload-will-apply-translations')}
            </Typography>
          </Box>
        )}

        {selectedTab === UISampleTab.SLIDE_OUT_MODAL && (
          <Box data-testid="slide-out-modal-tab-container">
            <Typography
              sx={{
                marginBottom: '2em',
              }}
              textAlign="center"
            >
              {t('service-portal.slide-out-modal')}
            </Typography>

            <Button
              onClick={() => setIsSlideOutModalOpen(true)}
              label={t('service-portal.open-slide-out-modal')}
              testId="open-slide-out-modal-button"
            />

            <SampleSlideOutModal
              isModalOpen={isSlideOutModalOpen}
              onClose={() => setIsSlideOutModalOpen(false)}
              onAction={() => alert(t('service-portal.action-button-clicked'))}
            />
          </Box>
        )}
      </Box>
    </section>
  )
}
